/*
.view-doc_edit-btn {
    float: right;
    margin-top: 10px !important;
}

.view-doc_delete-btn {
    float: right;
    margin-top: 10px !important;
}
*/

.view-doc-title {
    margin-bottom: 0;
}

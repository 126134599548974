.work-with-docs_search-form {
    float: right;
    width: 60%;
}

.work-with-docs_search-form > div{
    position: static;
    margin-top: 10px;
}

.work-with-docs {
    display: flex;
    justify-content: space-around;
}

.work-with-docs > div > label {
    z-index: 0;
}

.work-with-docs_search, .work-with-docs_search > div > div {
    width: 100% !important;
}
.search_form-container {
    width: 90%;
    margin: 0 auto;
    min-height: 380px;
}

.search_submit-btn {
    float: right;
    margin-top: 10px !important;
}

.search_form-container_filename-input > label {
    z-index: 0;
}

.Search-paper-164 {
    margin-top: 0 !important;
}

.search_meta-title {
    margin-bottom: 0;
}

.search_meta-section-container {
    border: 2px dashed #AFAFAF;
    border-radius: 3px;
    padding: 10px 15px;
    margin-top: 20px;
    position: relative;
}

.search_meta-section-container > button {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.search_meta-section-container.date {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.search_meta-section-container.date > div {
    width: 30%;
}

.search {
    display: flex;
    justify-content: space-around;
}

.search > div > label {
    z-index: 0;
}

.login_form-container {
    box-shadow: 1px 1px 10px grey;
    width: 55%;
    padding: 50px;
    margin: 90px auto 0;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
}

.login_form-container > div {
    width: 80%;
    margin: 0 auto;
}

.login_submit-btn {
    margin-top: 10px !important;
}

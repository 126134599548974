.preLoader {
    margin-top: 20px;
}

.top-right_search-box {
    width: 200px;
    position: absolute;
    top: 70px;
    right: 20px;
    display: flex;
}
.new-doc-type_form-container {
    width: 90%;
    margin: 0 auto;
    padding: 2px;
}

form .new-doc-type_form-container {
    overflow: hidden;
}

.new-doc-type_submit-btn {
    float: right;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.meta-title {
    margin: 0;
}

.meta-add_container {
    float: right;
    margin-bottom: 10px;
}

.meta-add_container h5 {
    display: inline-block;
    margin: 0 10px 0;
}

.meta-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    clear: both;
    border: 2px dashed #AFAFAF;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 3px;
    white-space: nowrap;
    position: relative;
}

.meta-section > div {
    width: 32%;
}

.meta-section > button {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 2px dashed #AFAFAF;
    border-left: 2px dashed #AFAFAF;
}

.meta-section_checkbox-container {
    display: grid;
    line-height: 60px;
}

.meta-section_checkbox-container > label {
    height: 30px;
}

.meta-section .meta-section_sort {
    border: 2px dashed #AFAFAF;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -2px;
    right: -32px;
    width: 28px;
    border-left: none;
}

.meta-section_sort button {
    min-width: 0;
    padding: 0;
    color: #303f9f;
}
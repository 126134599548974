.table-cell-with-action {
    cursor: pointer;
}
.table-cell-with-action:hover {
    color: blue;
}
.table-pagination > div > span {
    display: none;
}

.preLoader.table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

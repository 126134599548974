.new-doc_upload-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.new-doc_signature-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.new-doc_signature-section > button {
    margin-bottom: 5px;
}

.new-doc_upload-section > label {
    height: 48px;
}

.new-doc_upload-section > label > span {
    height: 100%;
}

.new-doc-type_meta-title {
    margin-bottom: 0;
}

.new-doc_meta-section-container {
    border: 2px dashed #AFAFAF;
    border-radius: 3px;
    padding: 10px 15px;
    margin-top: 20px;
    position: relative;
}

.new-doc_meta-section-container > button {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.new-document_search-box {
    width: 100%;
}
.language_buttons-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.language_buttons-container img {
    width: 35px;
    height: 20px;
    cursor: pointer;
}

.language_buttons-container img:first-child {
    margin-right: 10px;
}

.language_buttons-container img.active {
    width: 33px;
    height: 18px;
    border: 1px solid #000000;
}
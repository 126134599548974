.new-catalog_form-container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
}

.new-catalog_submit-btn {
    float: right;
    margin-top: 10px !important;
}

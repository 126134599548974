body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-layout-container {
    display: flex;
    flex-wrap: nowrap;
}

.layout-navigation {
    margin-top: 50px !important;
    width: 300px;
}

.navigation-item {
    padding-left: 0 !important;
}

.layout {
    margin-top: 40px;
    flex-wrap: nowrap !important;
}

.layout-navigation-link {
    display: block;
    text-decoration: none;
    color: black;
}

.layout-navigation-link.active {
    background: #AFAFAF;
}

.container {
    min-height: 500px;
    position: relative;
    padding: 0 40px;
    margin-bottom: 30px;
}
.language_buttons-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.language_buttons-container img {
    width: 35px;
    height: 20px;
    cursor: pointer;
}

.language_buttons-container img:first-child {
    margin-right: 10px;
}

.language_buttons-container img.active {
    width: 33px;
    height: 18px;
    border: 1px solid #000000;
}
.simplesearchbox-progressbar {
    position: relative;
    top: -33px;
    height: 100% !important;
    opacity: 0.2;
    width: 100%;
}
.table-cell-with-action {
    cursor: pointer;
}
.table-cell-with-action:hover {
    color: blue;
}
.table-pagination > div > span {
    display: none;
}

.preLoader.table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.preLoader {
    margin-top: 20px;
}

.new-catalog_form-container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
}

.new-catalog_submit-btn {
    float: right;
    margin-top: 10px !important;
}

.preLoader {
    margin-top: 20px;
}

.top-right_search-box {
    width: 200px;
    position: absolute;
    top: 70px;
    right: 20px;
    display: flex;
}
.new-doc-type_form-container {
    width: 90%;
    margin: 0 auto;
    padding: 2px;
}

form .new-doc-type_form-container {
    overflow: hidden;
}

.new-doc-type_submit-btn {
    float: right;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.meta-title {
    margin: 0;
}

.meta-add_container {
    float: right;
    margin-bottom: 10px;
}

.meta-add_container h5 {
    display: inline-block;
    margin: 0 10px 0;
}

.meta-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    clear: both;
    border: 2px dashed #AFAFAF;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 3px;
    white-space: nowrap;
    position: relative;
}

.meta-section > div {
    width: 32%;
}

.meta-section > button {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 2px dashed #AFAFAF;
    border-left: 2px dashed #AFAFAF;
}

.meta-section_checkbox-container {
    display: grid;
    line-height: 60px;
}

.meta-section_checkbox-container > label {
    height: 30px;
}

.meta-section .meta-section_sort {
    border: 2px dashed #AFAFAF;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -2px;
    right: -32px;
    width: 28px;
    border-left: none;
}

.meta-section_sort button {
    min-width: 0;
    padding: 0;
    color: #303f9f;
}
.searchbox-progressbar {
    position: relative;
    top: -33px;
    height: 100% !important;
    opacity: 0.2;
    width: 100%;
}
.work-with-docs_search-form {
    float: right;
    width: 60%;
}

.work-with-docs_search-form > div{
    position: static;
    margin-top: 10px;
}

.work-with-docs {
    display: flex;
    justify-content: space-around;
}

.work-with-docs > div > label {
    z-index: 0;
}

.work-with-docs_search, .work-with-docs_search > div > div {
    width: 100% !important;
}
.new-doc_upload-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.new-doc_signature-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.new-doc_signature-section > button {
    margin-bottom: 5px;
}

.new-doc_upload-section > label {
    height: 48px;
}

.new-doc_upload-section > label > span {
    height: 100%;
}

.new-doc-type_meta-title {
    margin-bottom: 0;
}

.new-doc_meta-section-container {
    border: 2px dashed #AFAFAF;
    border-radius: 3px;
    padding: 10px 15px;
    margin-top: 20px;
    position: relative;
}

.new-doc_meta-section-container > button {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.new-document_search-box {
    width: 100%;
}
/*
.view-doc_edit-btn {
    float: right;
    margin-top: 10px !important;
}

.view-doc_delete-btn {
    float: right;
    margin-top: 10px !important;
}
*/

.view-doc-title {
    margin-bottom: 0;
}

.dynamic-data-table_footer .table-pagination {
    padding: 0;
}

.dynamic-data-table_footer .table-pagination > div {
    float: left;
    padding: 0;
}
.search_form-container {
    width: 90%;
    margin: 0 auto;
    min-height: 380px;
}

.search_submit-btn {
    float: right;
    margin-top: 10px !important;
}

.search_form-container_filename-input > label {
    z-index: 0;
}

.Search-paper-164 {
    margin-top: 0 !important;
}

.search_meta-title {
    margin-bottom: 0;
}

.search_meta-section-container {
    border: 2px dashed #AFAFAF;
    border-radius: 3px;
    padding: 10px 15px;
    margin-top: 20px;
    position: relative;
}

.search_meta-section-container > button {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.search_meta-section-container.date {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.search_meta-section-container.date > div {
    width: 30%;
}

.search {
    display: flex;
    justify-content: space-around;
}

.search > div > label {
    z-index: 0;
}

.login_form-container {
    box-shadow: 1px 1px 10px grey;
    width: 55%;
    padding: 50px;
    margin: 90px auto 0;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
}

.login_form-container > div {
    width: 80%;
    margin: 0 auto;
}

.login_submit-btn {
    margin-top: 10px !important;
}



.main-layout-container {
    display: flex;
    flex-wrap: nowrap;
}

.layout-navigation {
    margin-top: 50px !important;
    width: 300px;
}

.navigation-item {
    padding-left: 0 !important;
}

.layout {
    margin-top: 40px;
    flex-wrap: nowrap !important;
}

.layout-navigation-link {
    display: block;
    text-decoration: none;
    color: black;
}

.layout-navigation-link.active {
    background: #AFAFAF;
}

.container {
    min-height: 500px;
    position: relative;
    padding: 0 40px;
    margin-bottom: 30px;
}